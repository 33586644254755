import React, { useState, useEffect, useRef } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useAdminContextStates } from "../context/AdminContext";
import { GrBlog } from "react-icons/gr";
import { FaBookOpen } from "react-icons/fa";

const AdminMenu = () => {
  const { setAdmin } = useAdminContextStates();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = () => {
    setIsOpen(false);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("admin");
    setAdmin(null);
  };

  const options = [
    {
      label: "create blog",
      href: "/admin/create-blog",
      icon: GrBlog,
    },
    {
      label: "all blogs",
      href: "/admin/all-blogs",
      icon: FaBookOpen,
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className="relative inline-block text-left" ref={menuRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          Admin
          <svg
            className="ml-2 -mr-1 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06-.02l3.25 3.24 3.25-3.24a.75.75 0 111.06 1.06l-3.75 3.75a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {options.map((option, index) => (
              <Link
                to={option.href}
                key={index}
                onClick={() => handleOptionClick(option)}
                className="flex px-4 py-2 gap-4 text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
                role="menuitem"
                tabIndex="-1"
                id={`menu-item-${index}`}
              >
                <option.icon size={20} />
                <span className="capitalize">{option.label}</span>
              </Link>
            ))}
            <Link
              to="/admin-login"
              className="flex items-center px-4 gap-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
              onClick={logout}
            >
              <AiOutlineLogout size={20} color="black" />
              <span>Logout</span>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminMenu;
