import img1 from "../../image/bgs.png";
import imgsoft from "../../image/img1.webp";
import "./home.css";
import { Helmet } from "react-helmet";
import Marquee from "react-fast-marquee";
import imgs2 from "../../image/Patronlogo.png";
import imgs3 from "../../image/logo-white.png";
import imgs5 from "../../image/BelgiumImmo.png";
import imgs7 from "../../image/twitter.png";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import ss from "../../image/ss.jpg";

import first from "../../image/Screenshot 2024-03-04 at 10.37.59 PM (1).png";
import second from "../../image/Screenshot 2024-03-04 at 10.38.13 PM.png";
import third from "../../image/Screenshot 2024-03-04 at 10.38.27 PM.png";
import fourth from "../../image/Screenshot 2024-03-04 at 10.38.53 PM.png";
import Slider from "react-slick";

// ===============================
// import homepageImageOne from "../../image/home/home-page-image (1).png";
// import homepageImageTwo from "../../image/home/home-page-image (2).png";
import software from "../../image/home/54c7e468-2487-477f-91ed-66f9f8c22800.jpeg"
import innoviative from "../../image/home/0df507f0-2992-4e8d-99e5-fc3c23d825c4.jpeg"
import Talent from "../../image/home/99397a48-d132-40f6-8199-6dc4047a1a9a.jpeg"
// import homepageImage2Three from "../../image/home/home-page-image (3).png";
import contactus from "../../image/contactus.svg";
import Changeslog from "./Changeslog";

const companyOverView = [
  {
    label: "Innovative Software Solutions",
    description:
      "<p>TechCreator is a technology software company that specializes in developing custom software solutions to help businesses of all sizes achieve their goals. <a href='https://techcreator.co/about'>Our team</a> of experienced developers, designers, and project managers work closely with clients to understand their business needs and develop software solutions that streamline operations, increase efficiency, and drive growth.</p>",
    imageUrl:innoviative ,
  },
  {
    label: "Software Products Discovery",
    description:
      "<p>We understand that every project is unique, and we work closely with our clients to ensure that their software products are tailored to meet their specific business needs. Whether you need a web application, a mobile app, or a custom software solution to automate your business processes,<strong>TechCreator software company</strong> has the expertise and experience to help you achieve your goals.</p>",
    imageUrl: software,
  },
  {
    label: "Talent Augmentation",
    description:
      "<p>TechCreator Software Development Company understands that businesses need a strong and reliable software development team to stay ahead of the competition. But finding and retaining the right talent can be challenging. That's where our talent augmentation services come in, helping businesses augment their teams with top talent to achieve their software development goals.</p>",
    imageUrl: Talent ,
  },
];

const Home = () => {
  const settings = {
    Infinite: true,
    speed: 500,
    slidesToShow: 2,
    slideToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title data-react-helmet="true">Techcreator Software Company</title>
        <meta
          name="description"
          content="TechCreator is a leading technology software company that specializes in custom software development for businesses worldwide. Contact us today to learn more"
          data-rh="true"
        />
        <meta
          name="keywords"
          content=" TechCreator, TechCreator Software House, technology software company, TechCreator software company, TechCreator Software Development Company, TechCreator's innovative software solutions , software development, technology solutions"
        />
        <meta name="robots" content="index,follow"></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <link rel="canonical" href="/" />
      </Helmet>
      <section
        id="header"
        className="d-flex align-items-center homeimg text-white "
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-12 col-md-10 mx-auto home-main">
              <div className="container1">
                <Fade left duration={1000}>
                  <div className="min-head col-lg-12 col-m  d-10 col-sm-12 pt-5 pt-lg-0 d-flex justify-content-center flex-column text-center mt-[-80px] md:mt-0">
                  <Changeslog/>
                    <p className=" text-3xl md:text-6xl maintext   col-lg-8  mx-auto">
                    Where <span className="text-textColor">Business</span> Meet <br/>
                      <span className="text-textColor">Automation</span>
                    </p>
                    <p className="pt-3  text-white text-center col-lg-9 self-center mx-3 md:mx-0 text-xl leading-relaxed col-lg-4">
                    An American based organization, TechCreator, We Don't just automate Business.We understand your business and Anticipate its Needs. Our vision  is to Empower  your Growth  journey with Cutting-Edge Automation solutions.   
                    </p>
                    <div className="mt-3 col-sm-6 d-flex col-lg-12 justify-content-center items-center self-center">
                      <button
                        className="btn btn-con1"
                        onClick={() => navigate("/contact")}
                      >
                        AUTOMATE YOUR BUSINESS
                      </button>
                    </div>
                  </div>
                </Fade>

                {/* <div className="col-12 col-lg-8 lg:h-[400px] mx-auto col-md-12 col-sm-12  mb-5 animation">
                  <img
                    src={img1}
                    style={{ boxShadow: "2px 2px 20px #8c40d4" }}
                    className="img-fluid "
                    alt="TechCreator software company"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="header2"
        className="d-flex align-items-center mt-5  text-dark"
      >
        <div className="container-fluid nav_bg">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <Fade left duration={1000}>
                  <div className="col-lg-7 col-md-12 col-sm-12 soft-img">
                    <img src={imgsoft} alt="TechCreator software company" />
                  </div>
                </Fade>
                <Fade
                  bottom
                  delay={1000}
                  duration={1000}
                  style={{ overflow: "hidden" }}
                >
                  <div className="col-lg-5 col-md-12 col-sm-12 hero-dev  d-flex justify-content-center flex-column">
                    <h2 className="mt-3 Develop">
                      Our Comprehensive Software Solutions
                    </h2>
                    <p className="pt-3  text-justify">
                      At TechCreator Software House, we believe that client
                      satisfaction is key to our success, and we are committed
                      to providing exceptional customer service at every stage
                      of the
                      <a href="https://en.wikipedia.org/wiki/Software_development_process">
                        software development process
                      </a>
                      . Our team works closely with clients to ensure that their
                      vision is realized, and their software
                      solution is a success.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col bg-gray-100 py-5 gap-20 px-5">
        {companyOverView.map((item, index) => (
          <div
            className={`flex sm:flex-row flex-col-reverse justify-around  m-auto gap-5 ${
              index % 2 === 0 ? "sm:flex-row-reverse" : ""
            }`}
            key={index}
          >
            {index % 2 === 0 ? (
              <Fade right duration={1000}>
                <div className="flex-1 max-w-2xl">
                  <img
                    src={item.imageUrl}
                    className="rounded-lg w-full max-h-72 object-contain "
                    alt={item.label}
                  />
                </div>
              </Fade>
            ) : (
              <Fade left duration={1000}>
                <div className="flex-1 max-w-2xl">
                  <img
                    src={item.imageUrl}
                    className="rounded-lg w-full max-h-72 object-contain"
                    alt={item.label}
                  />
                </div>
              </Fade>
            )}
            <Fade bottom delay={500} duration={1000}>
              <div className="flex-1 max-w-xl">
                <h1 className="font-bold text-[blueviolet]">{item.label}</h1>
                <div
                  className="text-justify text-[black]"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            </Fade>
          </div>
        ))}
      </section>

      <Fade bottom duration={1000}>
        <div className="col-md-12 clint-div text-center pt-5 my-5 p-1">
          <h2 className="text-[blueviolet]">CLIENT TESTIMONIALS</h2>
          <h3>What they say about us</h3>
        </div>
      </Fade>

      <section className="card-img">
        <div className="container py-5 ">
          <div className="flex  flex-wrap gap-1 justify-center  items-center p-5 overflow-x-hidden">
            <Slider
              {...settings}
              className="w-[260px]  sm:w-full  md:w-[1150px] "
            >
              <img className="object-cover" alt="reviews" src={first} />
              <img className="object-cover" alt="reviews" src={third} />
              <img className="object-cover" alt="reviews" src={second} />
              <img className="object-cover" alt="reviews" src={ss} />
              <img className="object-cover" alt="reviews" src={fourth} />
            </Slider>
          </div>

          <div className="row flex justify-center">
            {/* start count jsx */}
            <div className="row">
              <Fade bottom duration={2000}>
                <div className=" d-md-flex col-10 mx-auto">
                  <div className="col-md-4 col-sm-12 count">
                    <p>
                      +<span>50</span>
                      <span className="my-span">CLIENTS</span>{" "}
                    </p>
                  </div>

                  <div className="col-md-4 col-sm-12 count">
                    <p>
                      +<span>10</span>
                      <span className="my-span">COUNTRIES</span>{" "}
                    </p>
                  </div>

                  <div className="col-md-4 col-sm-12 count">
                    <p>
                      +<span>100</span>
                      <span className="my-span">PRODUCTS</span>{" "}
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="row">
              <Fade bottom duration={1000}>
                <Marquee gradient={false} speed={40} className="pt-5 marque">
                  <div className="col img-wrapper mx-5">
                    <img src={imgs2} alt="TechCreator " />
                  </div>
                  <div className="col img-wrapper mx-5">
                    <img src={imgs3} alt="TechCreator" />
                  </div>

                  <div className="col img-wrapper mx-5">
                    <img src={imgs5} alt="TechCreator" />
                  </div>

                  <div className="col img-wrapper mx-5">
                    <img src={imgs7} alt="technology software company" />
                  </div>
                </Marquee>
              </Fade>
            </div>
          </div>
          {/* count jsx end */}
        </div>
      </section>

      <div className="container-fluid py-5">
        <div className="row">
          <Fade bottom duration={1000}>
            <div className="flex justify-center items-center gap-x-5 flex-col md:flex-row  mx-auto ">
              <div className="max-w-[600px]">
                <div className="col-lg-12 col-md-12 col-sm-12  pt-lg-0  proj-header ">
                  <h2 className="font-semibold text-[blueviolet]">
                    Ready to bring your project to live with TechCreator?
                  </h2>
                </div>
                <p className="text-justify text-[black]">
                  Are you a TechCreator looking to develop your next big
                  project? Whether you're an experienced developer or just
                  starting out, we're here to help bring your ideas to life. At
                  our development company, we specialize in creating innovative
                  solutions for our clients across a variety of industries. From
                  mobile apps to web applications, we have the expertise to
                  build high-quality software that meets your needs and exceeds
                  your expectations.
                </p>

                <div className="pro-main col-lg-6 col-md-12 col-sm-12  d-md-flex  justify-content-start">
                  <button
                    className="proj-btn"
                    onClick={() => navigate("/contact")}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
              <div>
                <img src={contactus} alt="contact-us" className="w-[310px]" />
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};
export default Home;
