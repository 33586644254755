import "./about.css";
import React, { useState } from "react";
import img1 from "../../image/img15.webp";
import imgsen1 from "../../image/suadl.png";
import imgsen3 from "../../image/amir.png";
import imghr from "../../image/abbasl.png";
import imggm from "../../image/amad.png";
import imgpro1 from "../../image/travcont.webp";
import imgpro2 from "../../image/RiseImage.webp";
import imgpro3 from "../../image/raso.webp";
import imgpro4 from "../../image/Behance.webp";
import imgpro5 from "../../image/AddaImage.webp";
import imgpro6 from "../../image/bubbleImage.webp";
import imgpro7 from "../../image/MusafirImage.webp";
import imgpro8 from "../../image/GratitudeImage.webp";
import imgpro9 from "../../image/traficinfoImage.webp";
import imgpro10 from "../../image/PakfoneImage.webp";
import imgpro12 from "../../image/khpalmart.webp";
import imgpro13 from "../../image/petron1.png";
import imgpro14 from "../../image/repair1.png";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Helmet } from "react-helmet";
import Ourteam from "./Ourteam";
import { FaGithub, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { SiUpwork } from "react-icons/si";
import aboutUsImgOne from "../../image/about/about-image-1.svg";
import aboutUsImgTwo from "../../image/about/about-image-2.svg";
import aboutUsImgThree from "../../image/about/about-image-3.svg";

const About = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const Array = [
    {
      img: imgsen1,
      title: "Chief Executive Officer",
      name: "Saud Khan",
      link: "https://www.linkedin.com/in/saudkhan39",
      upwork: "https://www.upwork.com/freelancers/saudk19",
      x: "https://twitter.com/techcreatorco",
      gethub: "https://gitlab.com/saudkhanbpk ",
    },
    {
      img: imgsen3,
      title: "Project Manager",
      name: "Aamir Shehzad",
      link: "https://pk.linkedin.com/in/aamir-shahzad-77ba95191?trk=public_profile_browsemap",
      upwork: "https://www.upwork.com/agencies/techcreator/",
    },
    {
      img: imghr,
      title: "Admin Officer",
      name: "Abbas Khan",
      link: "https://www.linkedin.com/in/abbas-khan-0b814a219/",
      upwork: "https://www.upwork.com/agencies/techcreator/",
    },
    {
      img: imggm,
      title: "General Manager",
      name: "Amad Khan",
      link: "https://www.linkedin.com/in/amad-khan-11b394263/",
      upwork: "https://www.upwork.com/agencies/techcreator/",
    },
  ];

  // const Senior = [
  //   {
  //     img: imgsen1,
  //     title: "CEO FullStack Developer",
  //     name: "Saud Khan",
  //     link: "https://www.linkedin.com/in/saudkhan39/",
  //     upwork: "https://www.upwork.com/agencies/techcreator/",
  //   },
  //   {
  //     img: imgteam14,
  //     title: "CTO Full Stack Developer",
  //     name: "Aqib Khan",
  //     link: "https://www.linkedin.com/in/aqib-hassan-zeb-bb7b58211/",
  //     upwork: "https://www.upwork.com/agencies/techcreator/",
  //   },
  //   {
  //     img: imgteam1,
  //     title: "Full Stack Developer",
  //     name: "Imran Khan",
  //     link: "https://www.linkedin.com/in/imran-khan-160446216/",
  //     upwork: "https://www.upwork.com/agencies/techcreator/",
  //   },
  //   {
  //     img: imgteam4,
  //     title: "Full Stack Developer",
  //     name: "Saad Khan",
  //     link: "https://www.linkedin.com/in/saad-khan-42716a222/",
  //     upwork: "https://www.upwork.com/agencies/techcreator/",
  //   },
  // ];

  const projectInfo = [
    {
      img: imgpro1,
      link: "https://www.travcont.com/",
      head: "Travcont",
      para: "Travcont provide complete satisfaction for every traveler. We recognize that good service is more than competitive pricing",
      btn: "Go To Website",
    },
    {
      img: imgpro2,
      link: "https://risetech.pk/",
      head: "Ristech",
      para: "Modern web design and development using the latest web frameworks for providing the best user experience and the best performance.",
      btn: "Go To Website",
    },
    {
      img: imgpro3,
      link: "https://www.behance.net/gallery/92378157/Rasoya-App?tracking_source=project_owner_other_projects",
      head: "Rasoyia",
      para: "A rasoiya can also be a caterer who provides food for events such as weddings, parties, and corporate meetings.",
      btn: "Go To Website",
    },
    {
      img: imgpro4,
      link: "https://www.behance.net/gallery/92378157/Rasoya-App?tracking_source=project_owner_other_projects",
      head: "Mobile & Laptop Services",
      para: "This service enables users to make payments and transfer money using their mobile devices.",
      btn: "Go To Website",
    },
    {
      img: imgpro5,
      link: "https://www.upwork.com/o/companies/~010359a829c900bed1/portfolio/project/1484076208918925312",
      head: "Adda Bazar",
      para: " With the rise of e-commerce, some Adda bazar projects have moved online. These platforms connect small businesses and artisans with customers all over the world.",
      btn: "Go To Website",
    },
    {
      img: imgpro6,
      link: "https://www.behance.net/gallery/92465019/Bubbles-Laundry-App?tracking_source=project_owner_other_projects",
      head: "Bubbles Laundry",
      para: "Bubbles Laundry may need to upgrade its laundry facilities to improve the quality of its services and increase efficiency. ",
      btn: "Go To Website",
    },
    {
      img: imgpro7,
      link: "https://www.behance.net/gallery/89606661/Mosafir-App-for-Mobilink-Jazz?tracking_source=project_owner_other_projects",
      head: "Musafir App",
      para: "Musafir App is easy to use for booking both International as well as domestic flights, hotel bookings, holidays & packages booking",
      btn: "Go To Website",
    },
    {
      img: imgpro8,
      link: "https://www.behance.net/gallery/92140709/Gratitude",
      head: "Gratitude-Selfcare",
      para: "During your self-care routine, express gratitude for the opportunity to take care of yourself.",
      btn: "Go To Website",
    },
    {
      img: imgpro9,
      link: "https://traffikinfoo.netlify.app/",
      head: "Traffic Info",
      para: "This refers to a situation where the volume of vehicles on a particular road is higher than usual, causing delays and slow-moving traffic.",
      btn: "Go To Website",
    },
    {
      img: imgpro10,
      link: "https://pakfon.com/",
      head: "Pakfone",
      para: "Pakfones provides you world class mobile repair services, if you're in search of best mobile repair center in Pakistan,",
      btn: "Go To Website",
    },
    {
      img: imgpro12,
      link: "https://khpalmart.com/",
      head: "Khpal Mart",
      para: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..",
      btn: "Go To Website",
    },
    {
      img: imgpro12,
      link: "https://khpalmart.com/",
      head: "Khpal Mart",
      para: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..",
      btn: "Go To Website",
    },
    {
      img: imgpro13,
      link: "http://www.patronworks.net/home",
      head: "Patronworks",
      para: "Patronworks is a complete E-Commerce solution for your Restaurants, ",
      btn: "Go To Website",
    },
    {
      img: imgpro14,
      link: "https://repaircms.com/de/",
      head: "Repaircms",
      para: "The fact that they’ve been with us from the start and were patient, supportive, committed, and flexible is excellent.",
      btn: "Go To Website",
    },
  ];

  return (
    <>
      <Helmet>
        <title>About - Techcreator Software Company</title>
        <meta
          name="description"
          content="We have been providing quality services since 2001. We provide our clients with complete end-to-end solutions. We offer customized solutions..."
        />
        <meta
          name="keywords"
          content="techcreator, techCreator's UX/UI design, custom software development solutions, techcreator software house, softwarehouse, apps development, software industry, swabi software house, techcreator about"
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <section className="d-flex align-items-center aboutimg  text-white">
        <div className="container-fluid ">
          <div className="row" style={{ marginTop: "8%", marginBottom: "5%" }}>
            <div className="flex gap-5 justify-center items-center flex-col md:flex-row mt-32 px-5">
              <Fade left duration={1000}>
                <div className="max-w-3xl">
                  <h1 className="capitalize text-[blueviolet]">about us</h1>
                  <p className="text-justify">
                    At TechCreator, we pride ourselves on creating custom
                    software development solutions that help businesses achieve
                    their unique objectives. Our expertise in the latest
                    technologies and industry best practices allows us to create
                    solutions that are scalable, reliable, and easy to use.
                    Whether it's developing a custom software application,
                    implementing a data analytics solution, or creating an
                    intuitive TechCreator's UX/UI design, TechCreator has the
                    expertise to help businesses succeed. Contact us today to
                    learn more about our custom software development solutions.
                  </p>
                </div>
              </Fade>
              <div>
                <Fade right duration={1000}>
                  <img
                    src={aboutUsImgOne}
                    className="object-contain max-w-sm w-full"
                  />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="header2" className="d-flex align-items-center">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 pt-5 pt-lg-0  d-flex flex-column ">
                  <img src={img1} alt="techcreator" className="img1" />
                </div>
                <Fade bottom duration={2000}>
                  <div className="col-lg-7 col-md-12 col-sm-12 mb-5 ">
                    <Carousel
                      activeIndex={index}
                      onSelect={handleSelect}
                      className="slider"
                    >
                      <Carousel.Item className="carouselitem">
                        <h2 className="slidetitle1 text-[blueviolet]">WE ARE GEEKS</h2>
                        <p className="slidepara1">
                          We live and breath technology. We keep ourselves
                          updated: try new components, study new languages and
                          collaborating with the open source community.
                        </p>
                      </Carousel.Item>
                      <Carousel.Item className="carouselitem">
                        <h2 className="slidetitle1  text-[blueviolet]">WE ARE A TEAM</h2>
                        <p className="slidepara1">
                          We focus on integral solutions, exploring various
                          perspectives to solve a problem. Our work is the
                          result of collaborative thinking.
                        </p>
                      </Carousel.Item>
                      <Carousel.Item className="carouselitem">
                        <h2 className="slidetitle1  text-[blueviolet]">WE ARE AGNOSTIC</h2>
                        <p className="slidepara1">
                          We have experience in multiple technologies and
                          methodologies, which gives us the ability to think on
                          the best approach and tool for each problem.
                        </p>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
