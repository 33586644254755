import React from "react";
import logo from "../../image/logol.png";
import "./home.css";

const Changeslog = () => {
  return (
    <div className="flex items-center justify-center pb-3 tech-log">
      <img
        src={logo}
        alt="logo"
        className="w-24  pr-1 object-cover  shadow-lg "
      />
    </div>
  );
};

export default Changeslog;
