import React from "react";
import "./footer.css";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { GrTwitter } from "react-icons/gr";
import { SiUpwork } from "react-icons/si";
import logofot from "../../image/logol.png";
import { MdOutlineEmail } from "react-icons/md";
import { BsWhatsapp, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";
import us from "../../image/us.svg";

const sitesLink = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Our Services",
    href: "/services",
  },
  {
    label: "Contact",
    href: "/contact",
  },
  {
    label: "Blog",
    href: "/blog",
  },
];

const socialLinks = [
  {
    href: "https://www.facebook.com/techcreatorfb/",
    icon: FaFacebook,
  },
  {
    href: "https://linkedin.com/company/techcreator",
    icon: FaLinkedinIn,
  },
  {
    href: "https://www.upwork.com/agencies/techcreator/",
    icon: SiUpwork,
  },
  {
    href: "https://www.instagram.com/techcreatorco/",
    icon: FaInstagram,
  },
  {
    href: "https://twitter.com/techcreatorco",
    icon: GrTwitter,
  },
  {
    href: "https://www.youtube.com/@techcreator9512",
    icon: BsYoutube,
  },
];

const Footers = () => {
  return (
    <>
      <footer className="bg-black text-white px-10 py-4 sm:px-20">
        <div className="flex flex-col gap-y-4 gap-5 sm:flex-row justify-between border-b-2 border-gray-700 pb-4">
          <div className="flex flex-col gap-2  max-w-sm">
            <div className="flex items-center">
              <img
                src={logofot}
                alt="tech-creator logo"
                className="w-9 h-9"
              />
              <span className="flex flex-col leading-none">
                <p className="m-0 text-md">TECH</p>
                <p className="m-0 text-md ">CREATOR</p>
              </span>
            </div>
            <p className="text-justify m-0">
              We have been providing quality services since 2001. We provide our
              clients with complete end-to-end solutions. We offer customized
              solutions according to their needs.
            </p>
          </div>

          {/* ============================== */}
          <div className="flex flex-col">
            <h1 className="text-2xl">Links</h1>
            <div className="flex flex-col gap-1">
              {sitesLink.map((link, index) => (
                <Link
                  to={link.href}
                  key={index}
                  className="text-white hover:!text-violet-700"
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>
          {/* ================================= */}
          <div className="flex flex-col">
            <h1 className="text-2xl">Contact</h1>
            <div className="flex flex-col gap-2">
              <span className="flex items-center gap-3 cursor-pointer hover:!text-violet-700">
                <MdOutlineEmail size={20} />
                <p className="m-0">contact@techcreator.co</p>
              </span>
              <span className="flex items-center gap-3 cursor-pointer hover:!text-violet-700">
                <BsWhatsapp size={20} />
                <p className="m-0">+92-311-9265290</p>
              </span>
              <span className="flex items-center gap-3 cursor-pointer hover:!text-violet-700">
                <img
                  width={25}
                  className="rounded self-center h-[25px]"
                  src={us}
                />
                <p className="-m-1">+1-321-407-3272</p>
              </span>
              <div className="flex gap-3 flex-wrap">
                <Link
                  to="https://mentoga.com/saudkhan"
                  className="uppercase text-white bg-violet-800 p-2 rounded-xl"
                >
                  consultancy
                </Link>
                <Link
                  to="/caleneder"
                  className="uppercase text-white bg-violet-800 p-2 rounded-xl"
                >
                  appointment
                </Link>
              </div>
            </div>
          </div>
          {/* ========================================= */}
        </div>
        <div className="flex justify-center sm:justify-between flex-wrap items-center pt-3 gap-2">
          <div>
            <p className="m-0">&copy; 2024 TechCreator. All rights reserved.</p>
          </div>
          {/* ================ */}
          <div className="flex justify-between w-[16.9rem]">
            {socialLinks.map((link, index) => (
              <Link key={index} to={link.href} className="text-white text-xl">
                {<link.icon />}
              </Link>
            ))}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footers;
