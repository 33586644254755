import React, { useState, useRef } from "react";
import "./contact.css";
import contact from "../../image/contact.svg";
import { Formik, Form, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { GrTwitter } from "react-icons/gr";
import { ImWhatsapp } from "react-icons/im";
import Fade from "react-reveal/Fade";
import BookMeeting from "../../image/book-meeting.svg";

const Contact = () => {
  const [messageSent, setMessageSent] = useState(false);
  const formRef = useRef();

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    location: "",
    message: "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Please enter your name"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    // phone: yup.string().required("Please enter your phone number"),
    // location: yup.string().required("Please enter your location"),
    message: yup.string().required("Please enter your message"),
  });

  const sendEmail = (values) => {
    emailjs
      .sendForm(
        "service_2amws0d",
        "template_kpq47hs",
        formRef.current,
        "NO2pXno55HxArz-27"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessageSent(true);
          toast.success("Message sent successfully!");
          formRef.current.reset();
        },
        (error) => {
          console.error("Failed to send email:", error);
          toast.error("Failed to send message. Please try again later.");
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>Contact - Techcreator Software Company</title>
        <meta
          name="description"
          content="If you are interested in contacting us, please fill in the contact form and we will get back to you as soon as we can."
        />
        <meta
          name="keywords"
          content="techcreator, techcreator software house, techcreator software compant, sofwarehouse, apps development, software industry, swabi software house, techcreator contact us"
        />
        <link rel="canonical" href="/contact" />
      </Helmet>

      <ToastContainer />

      <section className="text-white bg-black h-[380px] sm:h-[320px] flex items-center justify-center pt-2">
        {/* Your header section */}

        <div className="flex items-center flex-col-reverse sm:flex-row justify-center gap-[0.5rem] sm:!gap-32">
          <Fade left delay={500} duration={1000}>
            <div className="flex items-center justify-center ">
              <div className="w-fit">
                <h1 className="text-3xl text-violet-600 font-bold">
                  Want to Say Something?
                </h1>
                <p>We are here to respond on your queries.</p>
                <NavLink
                  to="/meeting"
                  className=" text-white bg-violet-600 hover:bg-violet-900 p-2 rounded-md m-auto"
                >
                  BOOK MEETING
                </NavLink>
              </div>
            </div>
          </Fade>
          <Fade right delay={500} duration={1000}>
            <div className="w-[200px]">
              <img src={BookMeeting} alt="book-meeting" className="img-fluid" />
            </div>
          </Fade>
        </div>
      </section>

      <div className="container-fluid">
        <div className="container-fluid">
          <div className="row">
            <div className="col-8 mx-auto">
              <div className="row">
                <Fade bottom duration={1000}>
                  <div className="col-lg-5 col-md-12 col-sm-12 pt-5 pt-lg-0  d-flex flex-column cont-img">
                    <img src={contact} alt="Techcreator Software Company" />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div className="container">
            <div className="row contact-container">
              <Fade bottom duration={1000}>
                <div className="col-lg-12 main-card">
                  <div className="card card-shadow border-0 mb-4">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="contact-box p-4">
                          <h2 className="title text-[blueviolet]">
                            Contact Us
                          </h2>

                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => sendEmail(values)}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              isSubmitting,
                            }) => (
                              <Form ref={formRef}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="form-group mt-3">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <p className="text-danger">
                                        <ErrorMessage name="name" />
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mt-3">
                                      <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <p className="text-danger">
                                        <ErrorMessage name="email" />
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mt-3">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Phone"
                                        name="phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <p className="text-danger">
                                        <ErrorMessage name="phone" />
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group mt-3">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Location"
                                        name="location"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <p className="text-danger">
                                        <ErrorMessage name="location" />
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="form-group mt-3">
                                      <textarea
                                        className="form-control"
                                        rows="5"
                                        placeholder="Message"
                                        name="message"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <p className="text-danger">
                                        <ErrorMessage name="message" />
                                      </p>
                                    </div>
                                  </div>

                                  <div className="my-2">
                                    <hr />
                                    <h2 className="pb-3 text-[blueviolet] pt-3 font-bold">
                                      Services you are interested in
                                    </h2>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-10">
                                      <label className="inline-flex items-center">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                          Web Development
                                        </span>
                                      </label>
                                      <label className="inline-flex items-center">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                          App Development
                                        </span>
                                      </label>
                                      <label className="inline-flex items-center">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                          UI/UX Design
                                        </span>
                                      </label>
                                      <label className="inline-flex items-center">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                          Graphic Design
                                        </span>
                                      </label>
                                      <label className="inline-flex items-center">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                          SEO & Content Writing
                                        </span>
                                      </label>
                                      <label className="inline-flex items-center">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                          Project Management
                                        </span>
                                      </label>
                                      <label className="inline-flex items-center">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                          QA Testing
                                        </span>
                                      </label>
                                      <label className="inline-flex items-center">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                          Software Maintenance
                                        </span>
                                      </label>
                                      <label className="inline-flex items-center col-span-full sm:col-span-1">
                                        <input
                                          type="checkbox"
                                          className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                          DevOps
                                        </span>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-12 pt-3">
                                    <button
                                      type="submit"
                                      className="contact-btn"
                                      disabled={isSubmitting}
                                    >
                                      SUBMIT NOW
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>

                          {messageSent && (
                            <p
                              className="contact-box-text pt-3"
                              style={{ color: "black" }}
                            >
                              Thank you for contacting us. We will get in touch
                              with you as soon as possible.
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className="col-lg-4 bg-image"
                        style={{
                          backgroundImage:
                            "url(https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/1.jpg)",
                        }}
                      >
                        <div className="detail-box p-4">
                          <h3
                            className=" font-weight-light mb-3 text-[blueviolet]"
                            style={{ fontSize: "20px" }}
                          >
                            ADDRESS
                          </h3>
                          <p className="text-white op-7">
                            TechCreator Private Limited near Muneer Hospital,
                            Mardan Road Swabi.
                          </p>
                          <h3
                            className="text-[blueviolet] font-weight-light mb-3 mt-4"
                            style={{ fontSize: "20px" }}
                          >
                            CALL US
                          </h3>
                          <p className="text-white op-7">+92-311-9265290</p>
                          <div className="col-md-3 col-sm-12 col-xs-12 text-center">
                            <div className="">
                              <div className="flex">
                                <a
                                  href="https://www.facebook.com/techcreatorfb"
                                  className="p-2"
                                >
                                  <FaFacebook className="  w-6 h-6 text-blue-500" />
                                </a>
                                <a
                                  href="https://www.linkedin.com/company/techcreator/mycompany/"
                                  className="p-2"
                                >
                                  <FaLinkedinIn className=" w-6 h-6 text-blue-500 " />
                                </a>
                                <a
                                  href="https://www.instagram.com/techcreatorco/"
                                  className="p-2"
                                >
                                  <FaInstagram className="w-6 h-6 text-pink-600" />
                                </a>
                                <a
                                  href="https://twitter.com/techcreatorco"
                                  className="p-2"
                                >
                                  <GrTwitter className=" w-6 h-6 text-blue-600 " />
                                </a>

                                <a href="tel:+92-311-9265290" className="p-2">
                                  <ImWhatsapp className="text-white flex self-start top-10 bg-green-500   w-6 h-6  " />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
